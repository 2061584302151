
.home {
    background-color: #FCD405;
    width: 100%;
}

.responsive-image {
    width: 100%;
    height: auto;
}
