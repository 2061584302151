
@font-face {
  font-family: 'Arabica';
  src: local('Arabica'), url("fonts/Arabica.otf") format("opentype");
}


body {
  margin: 0;
  font-family: 'Arabica',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*code {*/
/*  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',*/
/*    monospace;*/
/*}*/

/*@font-face {*/
/*  font-family: 'Arabica';*/
/*  src: local('Arabica'), url("./fonts/Arabica.ttf") format("truetype");*/
/*}*/
